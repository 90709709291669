
<script>
import 'video.js/dist/video-js.css'
// import { videoPlayer } from 'vue-video-player'

export default {
    // components: {
    //     videoPlayer
    // },
    computed:{
        item(){
            return this.$store.getters["storage/item"];
        },
        linkFile(){
            return this.$store.getters["storage/linkFile"];
        },
        options(){
            let file = this.$store.getters["storage/item"];
            return {
                autoplay: true,
                language: 'en',
                autoSetup : true,
                fluid : true,
                responsive : true,
                muted: false,
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                sources: [{
                    type: `video/${file.extension}`,
                    src: this.$store.getters["storage/linkFile"],
                }],
            }
        }
    },
    data() {
        return {
        };
    },
    methods:{
      close(){
            this.$store.commit("share/SET_SHOWFILE",false);
            this.$store.commit("storage/SET_LINKFILE","");
            let video = document.getElementById("VideoView");
            video.style.display = 'none';
            var videoElement = document.getElementById('videoPlayer');
            videoElement.pause();
            videoElement.removeAttribute('src'); // empty source
            videoElement.load();
      },
      download(){
        this.$store.dispatch("storage/download",
        {
            directory : this.item.directory,
            hash_name : this.item.hash_name,
            file_name : (this.item.file_name.split('.').pop() == this.item.extension) ? this.item.file_name : this.item.file_name+"."+this.item.extension
        }
        );
      }
    }
};
</script>
<template>
    <div id="VideoView">
        <div v-if="item != null">
        <div class="d-flex justify-content-start align-items-center mb-2">
            <div class="d-flex justify-content-center align-items-center">  
                <span class="cursor-pointer px-2" @click="close()">
                    <img src="@/assets/images/left.png" alt width="100%" />
                </span>
                <span class=" px-2">
                    <img :src="require(`@/assets/images/icon/${item.icon}`)" alt />
                </span>
                <p class="font-size-16 p-0 text-white m-0">{{item.file_name}}</p>
            </div>
            <span class="cursor-pointer px-2">
                <b-dropdown  dropright class="bg-transparent more-menu">
                    <template #button-content>
                        <img src="@/assets/images/MoreWhite.png" alt />
                    </template>
                    <b-dropdown-item-button  v-if="!item.isFolder" @click="download()" >
                        <img src="@/assets/images/icon/Download.png" alt height="100%" />
                        <span class="ml-3">Download</span>
                    </b-dropdown-item-button>
                </b-dropdown>
            </span>
        </div>
        <div class="m-auto " id="player">
            <video controls width="100%" id="videoPlayer">
            </video>
        </div>
        </div>
    </div>
</template>