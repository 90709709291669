
<script>
export default {
    computed:{
        item(){
            return this.$store.getters["storage/item"];
        },
        linkFile(){
            return this.$store.getters["storage/linkFile"];
        },
    },
    data() {
        return {
            mainProps: {
            center: true,
            fluidGrow: true,
            blank: true,
            blankColor: '#bbb',
            // width: 500,
            // height: 500,
            }
        };
    },
    methods:{
      close(){
        this.$store.commit("share/SET_SHOWFILE",false);
        let video = document.getElementById("imageView");
        video.style.display = 'none';
      },
      download(){
        this.$store.dispatch("storage/download",
        {
            directory : this.item.directory,
            hash_name : this.item.hash_name,
            file_name : (this.item.file_name.split('.').pop() == this.item.extension) ? this.item.file_name : this.item.file_name+"."+this.item.extension
        }
        );
      }
    }
};
</script>
<template>
    <div id="imageView">
        <div v-if="item != null">
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-start align-items-center mb-2">
                <div class="d-flex justify-content-center align-items-center">  
                    <span class="cursor-pointer px-2" @click="close()">
                        <img src="@/assets/images/left.png" alt width="100%" />
                    </span>
                    <span class=" px-2">
                        <img :src="require(`@/assets/images/icon/${item.icon}`)" alt />
                    </span>
                    <p class="font-size-16 p-0 text-white m-0">{{item.file_name}}</p>
                </div>
                <span class="cursor-pointer px-2">
                    <b-dropdown  dropright class="bg-transparent more-menu">
                        <template #button-content>
                            <img src="@/assets/images/MoreWhite.png" alt />
                        </template>
                        <b-dropdown-item-button  v-if="!item.isFolder" @click="download()" >
                            <img src="@/assets/images/icon/Download.png" alt height="100%" />
                            <span class="ml-3">Download</span>
                        </b-dropdown-item-button>
                    </b-dropdown>
                </span>
            </div>
            <div>
                <span class="cursor-pointer px-2" @click="close()">
                    <img data-toggle="tooltip" title="Close" src="@/assets/images/icon/close-white.svg" alt width="100%" />
                </span>
            </div>
        </div>
        <div class="m-auto " id="player">
            <b-img-lazy v-bind="mainProps" :src="linkFile" alt ></b-img-lazy>
        </div>
        </div>
    </div>
</template>