<script>

export default {
  data(){
      return {
          isplay : false,
          stopped : true,
          currenttime : 0,
          totaltime : 0,
          progress : 0,
          player : document.getElementById('music')
      }
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if(modalId == "modal-music"){
          this.isplay = false;
          this.stopped = true;
          this.currenttime = 0;
          this.totaltime = 0;
          this.progress = 0;
      }
    })
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        this.$store.commit("share/SET_SHOWFILE",false);
      if(modalId == "modal-music"){
          this.isplay = false;
          this.stopped = true;
          this.currenttime = 0;
          this.totaltime = 0;
          this.progress = 0;
      }
    })
  },
  computed:{
        item(){
            let file = this.$store.getters["storage/item"];
            return file;
        },
        linkFile(){
            return this.$store.getters["storage/linkFile"];
        },
		percentComplete() {
			return parseInt((this.currenttime / this.totaltime) * 100);
		},
  },
    filters: {
        convertTimeHHMMSS(val) {
            let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);

            return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
        }
    },
  methods : {
      close(){
            this.$store.commit("share/SET_SHOWFILE",false);
            this.$store.commit("storage/SET_LINKFILE","");
            let Audio = document.getElementById("AudioView");
            Audio.style.display = 'none';
            var videoElement = document.getElementById('audioPlayer');
            videoElement.pause();
            videoElement.removeAttribute('src'); // empty source
            videoElement.load();
      },
      download(){
        this.$store.dispatch("storage/download",
        {
            directory : this.item.directory,
            hash_name : this.item.hash_name,
            file_name : (this.item.file_name.split('.').pop() == this.item.extension) ? this.item.file_name : this.item.file_name+"."+this.item.extension
        }
        );
      }
  }
};
</script>

<template>

    <div id="AudioView">
            <div v-if="item != null" class="d-flex justify-content-start align-items-center mb-2">
                <div class="d-flex justify-content-center align-items-center">  
                    <span class="cursor-pointer px-2" @click="close()">
                        <img src="@/assets/images/left.png" alt width="100%" />
                    </span>
                    <span class=" px-2">
                        <img :src="require(`@/assets/images/icon/${item.icon}`)" alt />
                    </span>
                    <p class="font-size-16 p-0 text-white m-0">{{item.file_name}}</p>
                </div>
                <span class="cursor-pointer px-2">
                    <b-dropdown  dropright class="bg-transparent more-menu">
                        <template #button-content>
                            <img src="@/assets/images/MoreWhite.png" alt />
                        </template>
                        <b-dropdown-item-button  v-if="!item.isFolder" @click="download()" >
                            <img src="@/assets/images/icon/Download.png" alt height="100%" />
                            <span class="ml-3">Download</span>
                        </b-dropdown-item-button>
                    </b-dropdown>
                </span>
            </div>
            <div v-if="item != null" class="m-auto d-flex justify-content-center align-items-center " id="playeraudio">
                <audio ref="audio" id="audioPlayer" width="100%" controls>
                </audio>
            </div>
    </div>
</template>